<template>
    <div class="Login">
        <el-card class="card">
            <h2>八号地综合零售系统v4.0.29</h2>
            <el-form
                ref="form"
                :model="form"
                label-width="80px"
                align="left"
                @submit.native.prevent
                :rules="rules"
                size="small"
            >
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="form.username" style="width: 260px" />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password" type="password" show-password />
                    <a class="cursor" @click="repass">忘记密码?</a>
                </el-form-item>
                <el-form-item label="验证码" prop="captcha">
                    <el-input v-model="form.captcha" @keypress.native="handlePressKey" />
                </el-form-item>
                <el-form-item>
                    <el-col :span="14">
                        <el-image :src="captchaUrl" />
                    </el-col>
                    <el-col :span="7">
                        <a class="cursor" style="line-height: 50px" @click="refreshCatpcha">点击刷新</a>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="9">
                        <el-button type="primary" @click="login" style="width: 100%">登录</el-button>
                    </el-col>
                    <el-col :span="9" :offset="1">
                        <el-button @click="register" style="width: 100%">注册</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="groupsBox" v-if="groupsFlag">
            <el-card class="groups">
                <div class="chooseGroup">
                    <el-row :gutter="10" style="margin-top: 16px">
                        <el-col :span="4" v-for="(data, code) in groups" :key="code">
                            <el-card>
                                <div class="group" @click="chooseGroup(code, data)">
                                    {{ data | data }}
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { get } from 'request/http';

export default {
    name: 'Login',
    data() {
        return {
            form: {
                username: '',
                password: '',
                captcha: '',
            },
            captchaUrl: '',
            groups: [],
            groupsFlag: false,
            rules: {
                username: [{ required: true, message: '请填写用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                captcha: [{ required: true, message: '请填写验证码', trigger: 'blur' }],
            },
        };
    },
    mounted() {
        //获取验证码
        this.refreshCatpcha();
    },
    filters: {
        data(data) {
            return data.length < 6 ? data : data.substr(0, 5) + '...';
        },
    },
    methods: {
        login() {
            const _this = this;
            this.$refs['form'].validate((valid) => {
                if (!valid) {
                    return false;
                }
                _this.$http
                    .post('/login', null, { params: _this.form })
                    .then(function (response) {
                        if (response.data.status === 200) {
                            _this.$store.commit('setSession', {
                                code: response.data.data.code,
                                realName: response.data.data.realName,
                                staffType: response.data.data.staffType,
                                deptType: response.data.data.deptType,
                            });
                            // Cookies.set('SESSION', response.data.data.sessionId, {domain: '.in.com'});
                            // _this.$router.push({ name: 'chooseGroup' });
                            (async () => {
                                _this.groups = await get('/ajaxManagedAndBelongCompany', true);
                                const codes = Object.keys(_this.groups);
                                const values = Object.values(_this.groups);
                                if (codes.length === 1) {
                                    _this.chooseGroup(codes[0], values[0]);
                                } else {
                                    _this.groupsFlag = true;
                                }
                            })();
                        } else {
                            _this.refreshCatpcha();
                            _this.$message.error(response.data.message);
                            _this.form.captcha = '';
                            _this.form.username = '';
                            _this.form.password = '';
                        }
                    })
                    .catch((rst) => {
                        _this.refreshCatpcha();
                    });
            });
        },
        register() {
            this.$router.push({ name: 'register' });
        },
        repass() {
            this.$router.push({ name: 'repass' });
        },
        refreshCatpcha() {
            this.captchaUrl = `${this.$proxyApiHostUrlSuffix}/captcha.jpg?t=` + new Date().valueOf();
        },
        handlePressKey(e) {
            if (e.key == 'Enter') {
                this.login();
            }
        },
        async chooseGroup(code, name) {
            await get('/ajaxChooseCompany?chosenCompanyCode=' + code);
            await this.$router.push({ name: 'main', params: { groupName: name } });
        },
    },
};
</script>

<style scoped>
.Login {
    background-image: url('../assets/login/bg.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

.Login .card {
    width: 460px;
    margin: 0 auto;
    margin-top: 10%;
}

.Login .el-input {
    width: 260px;
    margin-right: 16px;
}

.Login .cursor {
    font-size: 14px;
    color: #19c989;
    cursor: pointer;
}
.groupsBox {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.chooseGroup {
    padding: 16px;
}

.chooseGroup .group {
    font-size: 20px;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    height: 90px;
    background: #19c989;
    display: flex;
    justify-content: center;
    align-items: center;
}
.groups {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
}
</style>
